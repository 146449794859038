import { CookiePopup, Footer, RedLoading, SEO } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { InStoreExperienceActions } from 'modules/inStore';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cta, intro, utils } from 'style';

const MeasureUp: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(InStoreExperienceActions.StartInStoreExperience());
  }, [dispatch]);

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'measureUpPageSeo' })}
        url="https://find-your-fit.eu/en/measure-up"
        urlEN="https://find-your-fit.eu/en/measure-up"
        urlDE="https://find-your-fit.eu/de/measure-up"
        urlIT="https://find-your-fit.eu/it/measure-up"
        urlNL="https://find-your-fit.eu/nl/measure-up"
        urlFR="https://find-your-fit.eu/fr/measure-up"
      />
      <RedLoading />

      <section css={[intro.root, utils.wrapper]}>
        <div css={intro.carousel}>
          <motion.h1
            css={[intro.title, intro.carouselTitleAlt]}
            animate={{ y: [30, 0], opacity: [0, 1] }}
            transition={{ duration: 3, ease: 'circOut' }}
          >
            <FormattedMessage id="measureUpTitlePartOne" />
            <br />
            <span css={utils.fontFamilySansAlt}>
              <FormattedMessage id="measureUpTitlePartTwo" />
            </span>{' '}
          </motion.h1>
          <motion.div
            animate={{ y: [60, 0], opacity: [0, 1] }}
            transition={{ duration: 4, delay: 0.5, ease: 'circOut' }}
          >
            <div css={intro.carouselItem}>
              <img
                src={require('assets/images/carousel/nike-bra-carousel-3.jpg')}
                alt=""
              />
            </div>
          </motion.div>
        </div>

        <Link to="/measure-type" css={cta.button}>
          <FormattedMessage id="getStartedCta" />
        </Link>
      </section>
      <CookiePopup />
      <Footer />
    </PageTransition>
  );
};

export default MeasureUp;
